
<section class="mbr-section content5 cid-soI0fs63Vu mbr-parallax-background" id="content5-1q">

    

    <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2">
<div><br></div><div>Internet of Things</div></h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5">Network of connected smart devices</h3>
                
                
            </div>
        </div>
    </div>
</section>

<section class="features11 cid-soI4Ys1qiz" id="features11-1x">

    

    

    <div class="container">   
        <div class="col-md-12">
            <div class="media-container-row">
                <div class="mbr-figure" style="width: 50%;">
                    <img src="assets/images/vayunam-iot-952x952.jpg" alt="iot-vayunam" title="">
                </div>
                <div class=" align-left aside-content">
                    
                    <div class="mbr-section-text">
                        <p class="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5" style="text-align: justify">Internet of Things is a way to relate physical things in real life to the digital world. Having sensors on things at homes so that they get connected to different devices is one way of doing this. For example, if voice assistants, like Siri, Alexa, Cortana, could control the daily functions in our life, such as switching on lights, closing and opening doors, summoning required goods, vehicle tracking systems etc. is something what IoT is about.<br> 
<br>Vayunam helps your organization in developing products and services that may be just plain vanilla application of IoT or a higher set of solutions that integrate AI and IoT together. IoT helps to connect the physical world to the virtual one and AI helps to detect patterns and adapt to the surroundings. IoT is what allows devices to follow particular instructions and AI is what provides the correct timing for these instructions to be implemented&nbsp;<br></p>
                    </div>

                    
                </div>
            </div>
        </div> 
    </div>          
</section>

<section class="mbr-section info1 cid-soI5g2O46r" id="info1-1y">

    

    
    <div class="container">
        <div class="row justify-content-center content-row">
            <div class="media-container-column title col-12 col-lg-7 col-md-6">
                <h3 class="mbr-section-subtitle align-left mbr-light pb-3 mbr-fonts-style display-5">Let's help you navigate your next</h3>
                <h2 class="align-left mbr-bold mbr-fonts-style display-2">Ready to Elevate Your Experiences?</h2>
            </div>
            <div class="media-container-column col-12 col-lg-3 col-md-4">
                <div class="mbr-section-btn align-right py-4"><a class="btn btn-primary display-4" routerLink="/contact-us">Contact us</a></div>
            </div>
        </div>
    </div>
</section>
