
<section class="mbr-section content5 cid-soHYUF35aU mbr-parallax-background" id="content5-1k">

    

    <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2">Cyber Security</h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5">Practice of protecting networks, systems, hardware and data from digital attacks</h3>
                
                
            </div>
        </div>
    </div>
</section>

<section class="features11 cid-soHZa2A00s" id="features11-1o">

    

    

    <div class="container">   
        <div class="col-md-12">
            <div class="media-container-row">
                <div class="mbr-figure m-auto" style="width: 50%;">
                    <img src="assets/images/cyber-security-wheel-600x660.png" alt="Mobirise" title="">
                </div>
                <div class=" align-left aside-content">
                    
                    <div class="mbr-section-text">
                        <p class="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5" style="text-align: justify">
                        With an exponentially growing digital footprint comes an ever increasing threat and sophistication in cyberattacks.  This poses a huge challenge to organizations with growing digital eco-system. Coupled with privacy laws and governance around user data the imperatives around cybersecurity have been complex and do need an expert intervention or handholding on continuous basis. Vayunam has expertise around whole gamut of cybersecurity across an enterprise compute, applications, storage  and network elements that it brings to secure the all valuable identity and data
                        </p>
                    </div>

                    
                </div>
            </div>
        </div> 
    </div>          
</section>

<section class="cid-sqGQxDn7f2" id="image1-57">

    

    <figure class="mbr-figure container">
        <div class="image-block" style="width: 79%;">
            <img src="assets/images/artboard-2053x-1-952x544.png" width="1400" alt="Data Security">
            
        </div>
    </figure>
</section>

<section class="mbr-section content4 cid-sqGXSOtpLe" id="content4-5c">

    

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center pb-3 mbr-fonts-style display-2"><strong>CONSULTING</strong></h2>
                
                
            </div>
        </div>
    </div>
</section>

<section class="mbr-section article content12 cid-sqH3PhQYax" id="content12-5g">
     

    <div class="container">
        <div class="media-container-row">
           
            <div class="mbr-text counter-container col-12 col-md-6 mbr-fonts-style display-7">
                 <div class="media-container-row">
                <div class="img-item item1">
                    <img src="assets/images/securitystandards3x-512x293.png" alt="">
                  
                    <div class="img-caption">
                        <p class="mbr-fonts-style align-center mbr-black">
                           
                        </p>
                    </div>
                </div>
                </div>
                    
            </div>
             <div class="mbr-text counter-container col-12 col-md-6 mbr-fonts-style display-4">
               <h2 style="padding-left: 22px;"><strong> SME Track</strong></h2>
                <ul>
                    <li><strong>Vendor Risk Management
</strong></li><li><strong>
</strong><strong>Vulnerability Assessment &amp; Penetration Testing&nbsp;</strong></li><li><strong>Web Application Security&nbsp;</strong><br></li><li><strong>Forensic Investigator&nbsp;</strong><br></li><li><strong>Compliance Experts&nbsp;</strong><br></li><li><strong>GDPR Compliance&nbsp;</strong><br></li><li><strong>HIPAA&nbsp;</strong><br></li><li><strong>PCI</strong><br></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="mbr-section article content12 cid-sqGRE8dOBe" id="content12-5b">
     

    <div class="container">
        <div class="media-container-row">
            <div class="mbr-text counter-container col-12 col-md-6 mbr-fonts-style display-4">
                <h2 style="padding-left: 22px;"><strong> Programs</strong></h2>
                <ul>
                    <li><strong>Privacy Program Implementation
</strong></li><li><strong>
</strong><strong>Vendor Contracts and Risk Management&nbsp;</strong></li><li><strong>
</strong><strong>Identity and Access Management&nbsp;</strong></li><li><strong>
</strong><strong>Security Architecture Design&nbsp;</strong></li><li><strong>
</strong><strong>Information Security Policy&nbsp;</strong></li><li><strong>
</strong><strong>Information Risk Reviews and Management&nbsp;</strong></li><li><strong>
</strong><strong>Audit Management / Remediation&nbsp;</strong></li><li><strong>
</strong><strong>SOC Readiness and Compliance&nbsp;</strong></li><li><strong>
</strong><strong>Cyber Security Project</strong></li>
                </ul>
            </div>
            <div class="mbr-text counter-container col-12 col-md-6 mbr-fonts-style display-7">
                 <div class="media-container-row">
                
                     <img src="assets/images/compliance3x-888x888.png" alt="Security Standards" style="width: 99%;">
                    <div class="img-caption">
                        <p class="mbr-fonts-style align-center mbr-black">
                        
                        </p>
                    </div>
               
                </div>
                    
            </div>
        </div>
    </div>
</section>

<section class="mbr-section info2 cid-srk5bU4UKf" id="info2-6j">

    

    

    <div class="container">
        <div class="row main justify-content-center">
            <div class="media-container-column col-12 col-lg-3 col-md-4">
                <div class="mbr-section-btn align-left py-4"><a class="btn btn-primary display-4" routerLink="/contact-us">Contact us</a></div>
            </div>
            <div class="media-container-column title col-12 col-lg-7 col-md-6">
                <h2 class="align-right mbr-bold mbr-white pb-3 mbr-fonts-style display-2">Ready to Elevate Your Experiences?</h2>
                <h3 class="mbr-section-subtitle align-right mbr-light mbr-white mbr-fonts-style display-5">Let's help you navigate your next</h3>
            </div>
        </div>
    </div>
</section>
