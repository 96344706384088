<section class="mbr-section content5 cid-spXlnA3c9I" id="content5-3b">

    

    <div class="mbr-overlay" style="opacity: 0.7; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2">Contact us</h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5"></h3>
                
                
            </div>
        </div>
    </div>
</section>

<section class="mbr-section form1 cid-spXoKgraAN" id="form1-3m">

    

    
    <div class="container">
        <div class="row justify-content-center">
            <div class="title col-12 col-lg-8">
                <h2 class="mbr-section-title align-center pb-3 mbr-fonts-style display-2">Get in Touch with us</h2>
                <h3 class="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5">
                    We'd love to help you start exceeding your CX goals</h3>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="media-container-column col-lg-8" data-form-type="formoid">
                <!---Formbuilder Form--->
                <form class="mbr-form form-with-styler" [formGroup]="contactusForm"><input type="hidden" name="email" data-form-email="true" value="0KJwLR9QCtNVbWr3hDOTbRAE75uGlz2lkOvCGHnqyvBTHu3LeZpKpECTakGzREKHkOuAu1K1hkdnYvpsQIM4O38cvUZ78qHxGTXy3KUo5R7bESmrlsiqwLRTW2HnfAUr">
                    <div class="row">
                        <div *ngIf="thankyou"name="emailsentMsg" data-form-alert="" class="alert alert-success col-12">Thanks for filling out the form! We'll get back to you shortly.</div>
                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                        </div>
                    </div>
                    <div class="dragArea row">
                        <div class="col-md-4  form-group" data-for="name">
                            <label for="name" class="form-control-label mbr-fonts-style display-7">Name</label>
                            <input
                    class="form-control"
                    data-form-field="name"
                    id="name-form5-2"
                    name="name"
                    placeholder="Name"
                    type="text"
                    [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"
                    formControlName="userName"
                  />
				  <div
                    *ngIf="submitted && f.userName.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.userName.errors.required">
                      *User Name is required
                    </div>
                    <div *ngIf="f.userName.errors.pattern">
                      *Please must be a enter only charector value
                    </div>
                  </div>
                        </div>
                        <div class="col-md-4  form-group" data-for="email">
                            <label for="email-form1-3m" class="form-control-label mbr-fonts-style display-7">Email</label>
                            <input
                    class="form-control"
                    data-form-field="email"
                    id="email-form5-2"
                    name="email"
                    placeholder="E-mail"
                    type="email"
                    value=""
                    [ngClass]="{
                      'is-invalid': submitted && f.userEmail.errors
                    }"
                    formControlName="userEmail"
                  />
				  <div
                    *ngIf="submitted && f.userEmail.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.userEmail.errors.required">
                      *Email is required
                    </div>
                    <div *ngIf="f.userEmail.errors.email">
                      *Email must be a valid email address
                    </div>
                  </div>
                        </div>
                        <div data-for="phone" class="col-md-4  form-group">
                            <label for="phone-form1-3m" class="form-control-label mbr-fonts-style display-7">Phone</label>
                            <input
                    class="form-control"
                    data-form-field="mobileNumber"
                    id="Mobile Number-form5-2"
                    name="Mobile Number"
                    placeholder="Mobile Number"
                    type="tel"
                    value=""
                    [ngClass]="{
                      'is-invalid': submitted && f.userPhone.errors
                    }"
                    formControlName="userPhone"
                  />
				  <div
                    *ngIf="submitted && f.userPhone.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.userPhone.errors.required">
                      *Phone is required
                    </div>
                    <div *ngIf="f.userPhone.errors.pattern">
                      *Please, Enter 10 digit Mobile Number
                    </div>
                  </div>
                        </div>
                        <div data-for="message" class="col-md-12 form-group">
                            <label for="message-form1-3m" class="form-control-label mbr-fonts-style display-7">Message</label>
                            <textarea   class="form-control"
                    data-form-field="message"
                    name="message"
                    type="tel"
                    value=""
                    [ngClass]="{
                      'is-invalid': submitted && f.userPhone.errors
                    }"
                    formControlName="message"></textarea>
                        <div
                    *ngIf="submitted && f.userPhone.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.userPhone.errors.required">
                      *Message is required
                    </div>
                    
                  </div>
						</div>
                        <div class="col-md-12 input-group-btn align-center">
						<button
                    href=""
                    type="submit"
                    class="btn btn-form btn-primary display-4"
                    (click)="submit()"
                  >
                    SUBMIT
                  </button></div>
                    </div>
                </form><!---Formbuilder Form--->
            </div>
        </div>
    </div>

</section>

<section class="mbr-section article content1 cid-spXs6u9rJa" id="content1-3n">
    
     

    <div class="container">
        <div class="media-container-row">
            <div class="mbr-text col-12 mbr-fonts-style display-4 col-md-8"><p>In case you want to write to us, please email us at <strong>information[at]vayunam.com</strong></p></div>
        </div>
    </div>
</section>
