import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  URL: string;
  header: any;

  constructor(private http: HttpClient) {
    this.URL ='https://ru3t4ldks3.execute-api.ap-south-1.amazonaws.com/production/';
    const headerSettings: { [name: string]: string | string[] } = {};
    this.header = new HttpHeaders(headerSettings);
  }

  HttpEventResponse(event) {
    switch (event.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        const loaded = Math.round(event.loaded / 1024);
        break;
      case HttpEventType.Response:
        return event.body;
    }
  }
   contactus(data: any) {
    return this.http.post(this.URL + 'sendmail', data);
  }

}
