import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
	  contactusForm: FormGroup;
	   submitted = false;
	    thankyou = false;
		userData: any;
		  respData: any = {};
constructor(
    private router: Router,
    private fb: FormBuilder,
    private api: APIService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
	  this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
	  this.contactusForm = this.fb.group({
      userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      userEmail: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      userPhone: [
        '',
        [Validators.required, Validators.pattern('^((\\-?))?[0-9]{10}$')],
      ],
      
    });
	 }
	get f() {
    return this.contactusForm.controls;
  }
  submit() {
    this.submitted = true;

    if (this.contactusForm.invalid) {
          return;
    }
	
      this.userData = this.contactusForm.value;
     
      this.api.contactus(this.userData).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.reqStatus == true) {
                   
            this.thankyou = true;
           this.submitted = false;
            this.clearForm();
			
          } else {
          
           // alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //this.startLoader = false;
          console.log(err);
        }
      );
   
  }
 
   clearForm() {
    this.contactusForm.reset({
      userName: '',
      userPhone: '',
      userEmail: '',
      message: '',
    });
	    this.submitted = false;
  }

}
