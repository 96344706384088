<section class="cid-sqqaMjC1yX" id="footer1-4j">
   <div class="container">
      <div class="media-container-row content text-white">
         <div class="col-12 col-md-3">
            <div class="media-wrap"> <a routerLink="/"> <img alt="VayunamFooter" class="media-wrap-img" src="assets/images/logowhite.svg"/> </a> </div>
         </div>
         <div class="col-6 col-md-3 mbr-fonts-style display-7">
            <h5 class="pb-3">                    Location</h5>
            <p class="mbr-text">India<br/>North America<br/>Europe<br/>Middle East<br/>Asia Pacific <br/>Australia                </p>
         </div>
         <div class="col-6 col-md-3 mbr-fonts-style display-7">
            <h5 class="pb-3">                    Solutions                </h5>
            <p class="mbr-text"> <a class="text-white" routerLink="/digital-transformation">Digital Transformation</a><br/>
			<a class="text-white" routerLink="/internet-of-things">IoT</a><br/>
			<a class="text-white" routerLink="/data-analytics">Analytics</a><br/>
			<a class="text-white" routerLink="/cloud-transformation">Cloud Transformation</a><br/>
			<a class="text-white" routerLink="/cyber-security">Cyber Security</a><br/>
			<a class="text-white" routerLink="/artificial-intelligence">AI, ML, Cognitive</a><br/><br/></p>
         </div>
         <div class="col-12 col-md-3 mbr-fonts-style display-7">
            <h5 class="pb-3">Support</h5>
            <p class="mbr-text"><a class="text-white" routerLink="/terms-of-use">Terms of Use</a><br/>
			<a class="text-white" routerLink="/privacy-statement">Privacy Statement</a><br/>
			<a class="text-white" routerLink="/cookie-policy">Cookie Policy</a><br/>
			<a class="text-white" routerLink="/contact-us">Contact us</a></p>
         </div>
      </div>
      <div class="footer-lower">
         <div class="media-container-row">
            <div class="col-sm-12">
               <hr/>
            </div>
         </div>
         <div class="media-container-row mbr-white">
            <div class="col-sm-12 copyright">
               <p class="mbr-text mbr-fonts-style display-7">                        © Copyright 2021 Vayunam - All Rights Reserved </p>
            </div>
            <!--<div class="col-md-6">--> <!--    <div class="social-list align-right" mbr-if="iconsCount > 0">--> <!--        <div class="soc-item" mbr-if="iconsCount>0">--> <!--            <a href="https://twitter.com/" target="_blank">--> <!--                <span mbr-icon class="mbr-iconfont mbr-iconfont-social socicon-twitter socicon"></span>--> <!--            </a>--> <!--        </div>--> <!--        <div class="soc-item" mbr-if="iconsCount>1">--> <!--            <a href="https://www.facebook.com/" target="_blank">--> <!--                <span mbr-icon class="mbr-iconfont mbr-iconfont-social socicon-facebook socicon"></span>--> <!--            </a>--> <!--        </div>--> <!--        <div class="soc-item" mbr-if="iconsCount>2">--> <!--            <a href="https://www.youtube.com/" target="_blank">--> <!--                <span mbr-icon class="mbr-iconfont mbr-iconfont-social socicon-youtube socicon"></span>--> <!--            </a>--> <!--        </div>--> <!--        <div class="soc-item" mbr-if="iconsCount>3">--> <!--            <a href="https://instagram.com/" target="_blank">--> <!--                <span mbr-icon class="mbr-iconfont mbr-iconfont-social socicon-instagram socicon"></span>--> <!--            </a>--> <!--        </div>--> <!--        <div class="soc-item" mbr-if="iconsCount>4">--> <!--            <a href="https://plus.google.com/" target="_blank">--> <!--                <span mbr-icon class="mbr-iconfont mbr-iconfont-social socicon-googleplus socicon"></span>--> <!--            </a>--> <!--        </div>--> <!--        <div class="soc-item" mbr-if="iconsCount>5">--> <!--            <a href="https://www.behance.net/" target="_blank">--> <!--                <span mbr-icon class="mbr-iconfont mbr-iconfont-social socicon-behance socicon"></span>--> <!--            </a>--> <!--        </div>--> <!--    </div>--> <!--</div>--> 
         </div>
      </div>
   </div>
</section>

<input data-cookie-colorbg="rgba(234, 239, 241, 0.8300000000000001)" data-cookie-colorbutton="" data-cookie-colorlink="#424a4d" data-cookie-colortext="#424a4d" data-cookie-customdialogselector="null" data-cookie-text="We use cookies to give you the best experience. Read our &lt;a href='/cookie-policy'&gt;cookie policy&lt;/a&gt;." data-cookie-textbutton="Agree" data-cookie-underlinelink="true" name="cookieData" type="hidden"/>