import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ArtificialIntelligenceComponent } from './artificial-intelligence/artificial-intelligence.component';
import { CloudTransformationComponent } from './cloud-transformation/cloud-transformation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CyberSecurityComponent } from './cyber-security/cyber-security.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { DigitalTransformationComponent } from './digital-transformation/digital-transformation.component';
import { InternetOfThingsComponent } from './internet-of-things/internet-of-things.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutComponent },
  { path: 'artificial-intelligence', component: ArtificialIntelligenceComponent },
  { path: 'cloud-transformation', component: CloudTransformationComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },  
  { path: 'cyber-security', component: CyberSecurityComponent },  
  { path: 'data-analytics', component: DataAnalyticsComponent },  
  { path: 'digital-transformation', component: DigitalTransformationComponent },
  { path: 'internet-of-things', component: InternetOfThingsComponent },  
  { path: 'privacy-statement', component: PrivacyStatementComponent },   
  { path: 'terms-of-use', component: TermsOfUseComponent },     
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
