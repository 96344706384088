import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-transformation',
  templateUrl: './cloud-transformation.component.html',
  styleUrls: ['./cloud-transformation.component.css']
})
export class CloudTransformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
