
<section class="mbr-section content5 cid-sqv6g4oSgZ" id="content5-4q">

    

    <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2">Privacy Statement</h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5"></h3>
                
                
            </div>
        </div>
    </div>
</section>

<section class="mbr-section article content9 cid-srh3XET1t4" id="content9-5x">
    
     

    <div class="container">
        <div class="inner-container" style="width: 100%;">
            <hr class="line" style="width: 25%;">
            <div class="section-text align-center mbr-fonts-style display-7" style="text-align: justify">
              <h5><strong>Personal Information we collect and process and how we use it</strong></h5>
<ul>
<li><strong>Personal Information that we process</strong></li>

<p>For the purposes of this privacy statement, 'Personal Information' is any data that relates to an individual who may be identified from that data, or from a combination of a set of data, and other information which is in possession of Vayunam.</p>
<p>We collect the following categories of Personal Information:</p>
<ul>
<li>Information that you provide via our website, including information you provide when you register on our website e.g. name, email address, designation, company, country, name of your university, your field of academic study/concentration, expected year and month of your graduation, and telephone number;</li>
<li>Information about your computer and about your visits to and use of this Site, such as your Internet Protocol (IP) address, demographics, your computers’ operating system, and browser type and information collected via cookies</li>
<li>Cookie-related details are provided in the Cookie Policy</li>
</ul>
</ul>
<ul>
<li><strong>Use of your Personal Information</strong></li>

<p>We use your Personal Information for the following purposes:</p>

<ul>
<li>to provide better usability, troubleshooting and site maintenance;</li>
<li>to understand which parts of the website are visited and how frequently;</li>
<li>to create your user ID;</li>
<li>to identify you once you register on our website;</li>
<li>to contact you and respond to your questions or requests; and</li>
<li>to provide access to desirable content based on your preferences.</li>
<li>to process job application and alumni related requests, more details about which are available on corresponding portals of this website.&nbsp;</li></ul></ul>
<ul>
<li><strong>Legal basis of the processing</strong></li>

<p>We process your Personal Information when it is necessary for the performance of a contract to which you are the party or in order to take steps at your request prior to entering into a contract. This applies in any case where we provide services to you pursuant to a contract, such as when you use our website or process your registration on the website. If you do not provide the Personal Information that we need in order to provide our services, we will not be able to provide our services to you. We process your Personal Information for the performance of a contract in the following circumstances:</p>
<ul>
<li>to provide better usability, troubleshooting and site maintenance; and</li>
<li>to create your user ID.</li>
</ul>
<p>We process your Personal Information when it is necessary for the purposes of a legitimate interest pursued by us or a third party (when these interests are not overridden by your data protection rights). This applies in the following circumstances:</p>
<ul>
<li>to identify you once you register on our website; and</li>
<li>to contact you and respond to your questions or requests;</li>
<li>to understand which parts of the website are visited and how frequently; and</li>
<li>to provide access to desirable content based on your preferences.</li>
</ul>
</ul>
   <h5>Consequences of not providing Personal Information</h5>
<p>If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able to provide the corresponding service.</p>
<h5>Data recipients, transfer, and disclosure of Personal Information</h5>
<p>Vayunam does not share your Personal Information with third parties for their direct marketing purposes.</p>
<p>We share your Personal Information within</p>
<ul>
<li>Vayunam or with any of its subsidiaries;</li>
<li>Business partners;</li>
<li>Service vendors;</li>
<li>Authorized third-party agents; or</li>
</ul>
<p>Vayunam Private Limited does not sell your Personal Information to third parties.</p>
<p>In general, Vayunam Private Limited, domiciled in Gurgaon, is the data controller processing your Personal Information. The following applies only where the data controller processing your Personal Information is domiciled in the European Economic Area (“EEA”):</p>
<p>We transfer Personal Information to countries outside the EEA to third parties, including to countries that have different data protection standards to those which apply in the EEA. Our service providers are primarily located in USA, Canada, Australia, Singapore, Japan, Europe, India and UK. Where service providers process your Personal Information in countries deemed adequate by the European Commission, we rely on the European Commission's decision to protect your Personal Information.</p>
<p>For transfers to Vayunam and service providers outside the EEA, we use standard contractual clauses or rely on a service provider's (EU data protection authority approved) corporate rules that are in place to protect your Personal Information. Please contact us as set out below if you would like to obtain a copy of the methods used. When required, Vayunam discloses Personal Information to external law enforcement bodies or regulatory authorities, in order to comply with legal obligations.</p>
<h5><strong>Use of cookies</strong></h5>
<p>Please refer the Cookie Policy</p>
<h5><strong>Do Not Track</strong></h5>

<p>Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt-out of tracking by websites and online services. At the present time, the World Wide Web Consortium (W3C) has not yet established universal standards for recognizable DNT signals and therefore, Vayunam Private Limited and the Site do not recognize DNT.</p>

<h5><strong>Your Rights</strong></h5>

<p>Subject to the laws of your country, You have the right to access, correct, delete or transfer your Personal Information that we hold, including your profile and preferences. You also have the right to object to certain processing and, where we have asked for your consent to process your Personal Information, to withdraw this consent. Where we process your Personal Information because we have a legitimate interest in doing so (as explained above), you also have a right to object to this. These rights may be limited in some situations – for example, where we can demonstrate that we have a legal requirement to process your Personal Information.</p>
<p>If you are a Brazilian user, you have additional rights to take confirmation on the existence of your personal information, portability of your personal information to another service provider if applicable, anonymization or blocking of unnecessary information, information about public and private entities with which we have shared your data.</p>
<p>You can assert your rights in the corresponding sections where such information was provided or by contacting us at <u>information@vayunam.com</u></p>

<h5><strong>Data security</strong></h5>
<p>Vayunam adopts reasonable and appropriate security practices and procedures including administrative, physical security, and technical controls in order to safeguard your Personal Information.</p>

<h5><strong>Data retention</strong></h5>
<p>Personal Information will not be retained for a period more than necessary to fulfil the purposes outlined in this privacy statement unless a longer retention period is required by law or for directly related legitimate business purposes.</p>

<h5><strong>Linked websites</strong></h5>
<p>Our privacy practices regarding Personal Information that we collect and store about you through our portals such as Recruitment and Global Alumni will be as per the privacy policy of those portals.</p>
<p>Vayunam provides links to third-party websites and services. However, Vayunam is not responsible for the privacy statements, practices, or contents of such third-party websites.</p>

<h5><strong>Children’s Privacy</strong></h5>
<p>The Site does not knowingly solicit or collect personally identifiable information online from children under the age of 13 without prior verifiable parental consent. If Vayunam Private Limited learns that a child under the age of 13 has submitted personally identifiable information online without parental consent, it will take all reasonable measures to delete such information from its databases and to not use such information for any purpose (except where necessary to protect the safety of the child or others as required or allowed by law). If you become aware of any personally identifiable information we have collected from children under 13</p>
<p>Minors under 18 years of age may have the personal information that they provide to us deleted by sending an email to&nbsp;<u>information@vayunam.com</u>&nbsp;requesting deletion. Please note that, while we make reasonable efforts to comply with such requests, deletion of your personal information does not ensure complete and comprehensive removal of that data from all systems.</p>

<h5><strong>Updates to this privacy statement</strong></h5>
<p>Vayunam may change the data privacy practices and update this privacy statement as and when the need arises, and the same will be made available on the website. But our commitment to protect the privacy of website users will continue to remain.</p><p><br></p>
                    </div>
            <hr class="line" style="width: 25%;">
            </div>
        </div>
</section>

