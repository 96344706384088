import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-transformation',
  templateUrl: './digital-transformation.component.html',
  styleUrls: ['./digital-transformation.component.css']
})
export class DigitalTransformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
