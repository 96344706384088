<section class="mbr-section content5 cid-sqv6ySCiPY" id="content5-4v">

    

    <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2">Cookie Policy</h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5"></h3>
                
                
            </div>
        </div>
    </div>
</section>

<section class="mbr-section article content9 cid-srhdGzScRn" id="content9-5y">
    
     

    <div class="container">
        <div class="inner-container" style="width: 100%;">
            <hr class="line" style="width: 25%;">
            <div class="section-text align-center mbr-fonts-style display-7" style="text-align: justify">
                <p><strong>Cookies</strong>&nbsp;are small text files that, like most other Web servers, we place in your device that you use to access our website. This is done to recognize your device during a session or in your future visits to our website, primarily in order to provide a better user experience.<br></p>
<p><strong>Types of cookies used:</strong></p>
<p><strong>Session cookie:</strong>&nbsp;Session cookies remain only as long as a browsing session is active, and are intended to avoid user inconvenience during browsing. These cookies allow websites to link the actions of a user during a browser session and expire at the end of the browsing session. Session cookies also assist the user in navigating the website and allow the user to access secure parts of the webpage when logged in.</p>
<p><strong>Persistent cookie:</strong>&nbsp;Persistent cookies are stored on a user’s device even after the termination of a browsing session. It helps in recalling the preferences or actions of the user. Persistent cookies are used for a variety of purposes such as retaining the visitor’s language and regional preference(s) at the end of each browsing session. We use the services of third-party analytics service provider to analyze cookies to carry out a behavioral analysis in order to enhance user experience and convenience, and also to provide targeted and relevant content to visitors. Depending on the type and settings of your browser, cookies may be accepted by default. You may change the settings of your browser to delete existing cookies or prevent future cookies from being automatically accepted. If you disable cookies, certain parts and functions of our website may not be available. You can learn more about cookies at&nbsp;<a href="http://www.aboutcookies.org/">www.aboutcookies.org</a>. You can delete all cookies that are already on your browser by clearing its browsing history.</p>
<p><strong>Third Party Cookies</strong></p>
<p>We use cookies from third-party companies to provide us with insights about our sites, help us run targeting advertising campaigns, measure the effectiveness of our advertising campaigns and to make the advertisements more relevant to you. These companies use cookies to collect information about your browsing activities on our sites, such as the pages you visit, the links you click, the assets you download and how long you are on our sites. Based on your browsing of our website you may see our advertisements while browsing through our advertisement partner websites and/or their network websites. In order to opt-out from receiving such advertisements, you have to set your preferences on our partner websites. Alternatively, you may also visit the Network Advertising Initiative or Digital Advertising Alliance websites to opt-out.</p>
<p>For more information on how these companies collect and use the information on our behalf, please refer to their privacy/cookie policies and other details provided in the table below:</p>
<table width="100%">
<thead>
<tr>
<td>
<strong>Thirdparty companies</strong>
</td>
<td>
<strong>Purpose</strong>
</td>
<td>
<strong>Applicable Privacy/Cookie Policy Link</strong>
</td>
</tr>
</thead>
<tbody>
<tr>
<td>
Google
</td>
<td>
Show targeted, relevant advertisements and re-targeted advertisements to visitors of Vayunam websites
</td>
<td>
<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a><br> Opt Out:&nbsp;<a href="https://adssettings.google.com/">https://adssettings.google.com/</a><br> NAI:&nbsp;<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a><br> DAA:&nbsp;<a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
</td>
</tr>
<tr>
<td>
Facebook
</td>
<td>
Show targeted, relevant advertisements
</td>
<td>
<a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a><br> Opt Out:&nbsp;<a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a>
</td>
</tr>
<tr>
<td>
LinkedIn
</td>
<td>
Show targeted, relevant advertisements and re-targeted advertisements to visitors of Vayunam websites
</td>
<td>
<a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a><br> Opt-out:&nbsp;<a href="https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences">https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences</a>
</td>
</tr>
<tr>
<td>
    Youtube
</td>
<td>
Show targeted, relevant advertisements. Show embedded videos on Vayunam websites
</td>
<td>
<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a><br> Opt Out:&nbsp;<a href="https://adssettings.google.com/">https://adssettings.google.com/</a><br> NAI:&nbsp;<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a><br> DAA:&nbsp;<a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
</td>
</tr>
<tr>
<td>
Twitter
</td>
<td>
Show targeted, relevant advertisements and re-targeted advertisements to visitors of Vayunam websites
</td>
<td>
<a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a><br> Opt out:&nbsp;<a href="https://twitter.com/personalization">https://twitter.com/personalization</a><br> DAA:&nbsp;<a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
</td>
</tr>
</tbody>
</table>
<p><strong><br></strong></p><br><p><strong>Cookie retention</strong></p>
<p>Information collected via cookies (other than the third-party cookies) will be retained for a maximum period of 2 years. Retention period of third-party cookies is mentioned in their Privacy/Cookie Policy (available as a hyperlink in the table above).</p>
<p>&nbsp;</p>
                    </div>
            <hr class="line" style="width: 25%;">
            </div>
        </div>
</section>

