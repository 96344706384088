
<section class="mbr-section content5 cid-soGI1HVvZX mbr-parallax-background" id="content5-z">

    

    <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2">Digital Transformation</h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5">Digital transformation is the integration of digital technology into all areas of a business</h3>
                
                
            </div>
        </div>
    </div>
</section>

<section class="mbr-section content4 cid-soGmcjAoXh" id="content4-u">

    

    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center pb-3 mbr-fonts-style display-2">Vayunam brings the power of Digital Transformation to you</h2>
                
                
            </div>
        </div>
    </div>
</section>

<section class="features11 cid-soGIbNisq0" id="features11-10">

    

    

    <div class="container">   
        <div class="col-md-12">
            <div class="media-container-row">
                <div class="mbr-figure m-auto" style="width: 50%;">
                    <img src="assets/images/digitaltransformationnew.svg" alt="Vayunam-digital-Transformation" title="">
                </div>
                <div class=" align-left aside-content">
                    
                    <div class="mbr-section-text">
                        
                    </div>

                    <div class="block-content">
                        <div class="card p-3 pr-3">
                            <div class="media">
                                <div class=" align-self-center card-img pb-3">
                                    <span class="mbri-extension mbr-iconfont"></span>
                                </div>     
                                <div class="media-body">
                                    
                                </div>
                            </div>                

                            <div class="card-box">
                                <p class="block-text mbr-fonts-style display-7" style="font-size: 1.5rem;">
                                   The world of technology is ever evolving and going digital is not just a technology play but pan across the business workflows that need to be refined for encompassing and enabling new business models, delivering worthy customer experience, enhancing productivity and driving informed decision making through rational data analytics. 
                                </p>
                            </div>
                        </div>

                        <div class="card p-3 pr-3">
                            <div class="media">
                                <div class="align-self-center card-img pb-3">
                                    <span class="mbri-drag-n-drop2 mbr-iconfont"></span>
                                </div>     
                                <div class="media-body">
                                    
                                </div>
                            </div>                

                            <div class="card-box">
                                <p class="block-text mbr-fonts-style display-7" style="font-size: 1.5rem;">
                                    As an experienced and reliable partner, Vayunam has capabilities to guide your organization towards a tangible digital transformation. With deep expertise in innovative delivery models, strategic technology partnerships and focussed consulting, we will help you achieve the digital transformation mission.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>          
</section>

<section class="header1 cid-soI63iJxZR" id="header16-22">

    

    

    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-md-10 align-center">
                <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">Ready to Elevate Your Experiences?</h1>
                
                <p class="mbr-text pb-3 mbr-fonts-style display-5">
                    Let's help you navigate your next
                </p>
                <div class="mbr-section-btn"><a class="btn btn-md btn-black-outline display-4" routerLink="/contact-us">Contact Us</a></div>
            </div>
        </div>
    </div>

</section>

