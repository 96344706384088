import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artificial-intelligence',
  templateUrl: './artificial-intelligence.component.html',
  styleUrls: ['./artificial-intelligence.component.css']
})
export class ArtificialIntelligenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
