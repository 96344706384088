import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaLoaderService,
  RecaptchaModule,
} from 'ng-recaptcha';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ArtificialIntelligenceComponent } from './artificial-intelligence/artificial-intelligence.component';
import { CloudTransformationComponent } from './cloud-transformation/cloud-transformation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CyberSecurityComponent } from './cyber-security/cyber-security.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { DigitalTransformationComponent } from './digital-transformation/digital-transformation.component';
import { InternetOfThingsComponent } from './internet-of-things/internet-of-things.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

const globalSettings: RecaptchaSettings = {siteKey: '6LeO5ckZAAAAAHCza41XHWkJ4NbOJ5mp1plD8IXK'};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ArtificialIntelligenceComponent,
    CloudTransformationComponent,
    ContactUsComponent,
    CookiePolicyComponent,
    CyberSecurityComponent,
    DataAnalyticsComponent,
    DigitalTransformationComponent,
    InternetOfThingsComponent,
    PrivacyStatementComponent,
    TermsOfUseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: globalSettings,
} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
