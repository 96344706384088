<section class="menu cid-sozX60aBGZ" id="menu1-0" once="menu">
<nav class="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-toggleable-sm bg-color transparent">
<button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
<div class="hamburger">
<span></span>
<span></span>
<span></span>
<span></span>
</div>
</button>
<div class="menu-logo">
<div class="navbar-brand">
<span class="navbar-logo">
<a routerLink="/">
<img alt="Vayunam" src="assets/images/logowhite.svg" style="height: 7.5rem;"/>
</a>
</span>
</div>
</div>
<div class="collapse navbar-collapse" id="navbarSupportedContent">
<ul class="navbar-nav nav-dropdown" data-app-modern-menu="true"><li class="nav-item"><a aria-expanded="false" class="nav-link link text-white text-primary display-4" routerLink="/">Home</a></li><li class="nav-item"><a aria-expanded="false" class="nav-link link text-white text-primary display-4" routerLink="/about-us" >About us</a></li>
<li class="nav-item dropdown"><a aria-expanded="true" class="nav-link link dropdown-toggle text-white display-4" data-toggle="dropdown-submenu">Services</a>
<div class="dropdown-menu"><a class="dropdown-item text-white display-4" routerLink="/digital-transformation">Digital Transformation</a>
<a class="dropdown-item text-white display-4" routerLink="/internet-of-things">IoT (Internet of Things)</a>
<a class="dropdown-item text-white display-4" routerLink="/cyber-security">Cyber Security</a>
<a class="dropdown-item text-white display-4" routerLink="/cloud-transformation">Cloud Transformation / Migration</a>
<a class="dropdown-item text-white display-4" routerLink="/data-analytics">Analytics - Big Data, Datalakes</a>
<a class="dropdown-item text-white display-4" routerLink="/artificial-intelligence">Artificial Intelligence and Industry 4.0 </a></div></li></ul>
<div class="navbar-buttons mbr-section-btn"><a class="btn btn-sm btn-secondary display-4" routerLink="/contact-us"><span class="mobi-mbri mobi-mbri-phone mbr-iconfont mbr-iconfont-btn"></span>
                    
                    Contact Us</a></div>
</div>
</nav>
</section>