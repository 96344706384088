<section class="mbr-section content5 cid-spX0Z7txtE" id="content5-32">
    <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(35, 35, 35);">
    </div>
    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-2"></h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5"></h3>
            </div>
        </div>
    </div>
</section>
<section class="cid-srgltJ7Qr0" id="image1-5l">
    <figure class="mbr-figure container">
        <div class="image-block" style="width: 20%;">
            <img src="assets/images/vayunam-logo-3.svg" width="1400" alt="Vayunamlogocolor">
        </div>
    </figure>
</section>
<section class="mbr-section content4 cid-spX0Z845Tg" id="content4-33">
    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-12">
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5" style="font-size: 2rem;"><strong>"Vayunam”</strong> is a Sanskrit word and means knowledge, wisdom, faculty of perception, or <strong>"Applied Intelligence"</strong></h3>
            </div>
        </div>
    </div>
</section>

<section class="mbr-section article content9 cid-spX6nCowcb" id="content9-39">
    <div class="container">
        <div class="inner-container" style="width: 100%;">
            <hr class="line" style="width: 25%;">
            <div class="section-text align-center mbr-fonts-style display-7" style="text-align: justify; font-size: 1.5rem;"><br>&nbsp;Leaving aside the scourge of the pandemic, the year 2020 has been constructively destructive. Several laid-out norms and practices got obliterated with the speed that was unimaginable a few months ago. The impact of digital disruption and adoption of a new paradigm of digital transformation and speed of Industry 4.0 elements adoption and demand has been astounding.&nbsp;<div><br></div><div>In the realm of information, communication, and technology, it is elementary that physical boundaries and presence are no-longer imperative. Sensing an opportunity, a small group of seasoned business and technology professionals came together to form “Vayunam”, which is the platform to aggregate and nurture the intellectual prowess available in India that can serve the digital needs across the world. India’s majority population is young, educated, and in a productive age-group, and its superiority in software development, IT, and ITES is reckoned with worldwide.&nbsp;</div><div><br></div><div>Vayunam inculcates a strong ethos of integrity and excellence, which is imbibed strongly in the founders of the organization. The founders bring global experience in niche consulting, delivering turn-key projects, doing strategic outsourcing, product development, business transformation engagement, and managed services operations across a spectrum of industry domains. Having worked in global organizations like Accenture, IBM, Ericsson, DHL, Vodafone, Airtel, Reliance, MTS Russia, IFC (World Bank initiative), eVector (Intel-Reuters portfolio), Maxis etc., they bring world-class expertise with them. They have been instrumental in delivering several path-breaking works, operational excellence and have the distinction of managing teams of several thousand in the technology domain.</div></div>
            <hr class="line" style="width: 25%;">
        </div>
        </div>
</section>